'use client'

//Libraries
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { TriangleRightIcon } from '@radix-ui/react-icons'
import React, { useEffect, useState } from 'react'

import { CSS, styled } from '../../stitches.config'
import { sharedTypographyProps } from '../../utils/stitchesShared-utils'
import { Flex } from '../Flex'
import { Text } from '../Text'
import * as Dropdown from './DropdownSharedUi'

export interface DropdownItemType {
  /**
   * Id of the dropdown item.
   */
  key: string

  /**
   * If true, disables the menu item.
   * Defaults to false
   */
  disabled?: boolean

  /**
   * The icon to display for the dropdown item. Shown on left side of text.
   */
  iconName?: string

  /**
   * The text to display for the dropdown item.
   */
  label?: string

  css?: CSS

  type?: 'divider' | 'text'

  disableCloseOnSelect?: boolean
}

export interface DropdownMenuItemType extends DropdownItemType {
  children?: DropdownItemType[]
}

export interface DropdownMenuProps {
  sideUi?: React.ReactNode
  /**
   * The options list to show.
   */
  options?: DropdownMenuItemType[]

  /**
   * The max height of the list Container.
   */
  maxHeight?: number

  /**
   * An offset in pixels from the "start" or "end" alignment options.
   */
  alignOffset?: number

  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number

  /**
   * The distance in pixels from the edges.
   */
  collisionPadding?: number

  initiallySelectedOption?: DropdownItemType

  /**
   * The preferred side of the trigger to render against when open.
   */
  side?: 'top' | 'right' | 'bottom' | 'left'

  /**
   * The preferred alignment against the trigger.
   */
  align?: 'start' | 'center' | 'end'

  /**
   * Give css class name to dropdown component.
   */
  className?: string

  /**
   * Give css property to dropdown component trigger.
   */
  css?: CSS

  /**
   * Give css property to dropdown component content.
   */
  contentMenuCss?: CSS

  /**
   * Text variant for all items.
   */
  textVariant?: sharedTypographyProps['variant']

  /**
   * The main color of the dropdown
   */
  color?: string

  /**
   * Content for button trigger.
   */
  children?: React.ReactNode

  asChild?: boolean

  /**
   * Return selected menu option.
   */
  onSelectMenuItem?: (value: DropdownMenuItemType, e: Event) => void

  /**
   * Return selected sub menu option.
   */
  onSelectSubMenuItem?: (value: DropdownMenuItemType) => void

  /**
   * Return open state of the dropdown.
   */
  onOpenChange?: (value: boolean) => void

  /**
   * If true, the user can scroll the page when the dropdown is open.
   */
  allowScrollWhenOpen?: boolean // https://www.radix-ui.com/primitives/docs/components/dropdown-menu > modal prop on root

  inPortal?: boolean
}

export const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      textVariant = 'subtitle2',
      options = [],
      maxHeight = 300,
      className = '',
      css = {},
      contentMenuCss = {},
      children,
      side = 'bottom',
      align = 'start',
      color = '$gs3',
      sideOffset = 4,
      alignOffset = 0,
      asChild = false,
      collisionPadding = 0,
      onOpenChange,
      onSelectMenuItem,
      onSelectSubMenuItem,
      initiallySelectedOption,
      sideUi,
      allowScrollWhenOpen = false,
      inPortal = true,
    },
    forwardedRef,
  ) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState<DropdownMenuItemType | undefined>(initiallySelectedOption)
    const [selectedSubOption, setSelectedSubOption] = useState<DropdownItemType>()
    const showActiveState = !!initiallySelectedOption

    useEffect(() => {
      setSelectedMenuItem(initiallySelectedOption)
    }, [initiallySelectedOption])

    const handleMenuClick = (value: DropdownMenuItemType, e: Event) => {
      if (showActiveState) {
        const item = options.find(option => option.key === value.key)
        if (item) setSelectedMenuItem(undefined)
      }
      if (onSelectMenuItem) onSelectMenuItem(value, e)
    }

    const handleSubClick = (value: DropdownItemType) => {
      setSelectedSubOption(value)
      if (onSelectSubMenuItem) onSelectSubMenuItem(value)
    }

    const handleOpen = (state: boolean) => {
      if (onOpenChange) onOpenChange(state)
      setOpenDropdown(state)
    }

    return (
      <Container className="container">
        <DropdownMenuRoot onOpenChange={handleOpen} open={openDropdown} css={css} modal={!allowScrollWhenOpen}>
          <DropdownMenuTrigger asChild={asChild}>{children}</DropdownMenuTrigger>
          <WithPortalWrapper inPortal={inPortal}>
            <Dropdown.DropdownMenuContent
              css={{
                backgroundColor: color,
                maxHeight,
                ...contentMenuCss,
                '&[data-side="top"]': {
                  boxShadow:
                    '$colors$b11 0px -5px 5px -3px, $colors$b11 0px -5px 10px 1px, $colors$b11 0px -3px 14px 2px',
                },
                '&[data-side="bottom"]': {
                  boxShadow:
                    '$colors$b11 -2px 5px 5px -3px, $colors$b11 0px 5px 10px 1px, $colors$b11 0px 3px 14px 2px',
                },
              }}
              sideOffset={sideOffset}
              alignOffset={alignOffset}
              className={className}
              side={side}
              align={align}
              variant={'light'}
              softBorder="disable"
              ref={forwardedRef}>
              <Flex
                direction={{
                  base: 'column',
                  bp1: 'row',
                }}>
                <Flex direction={'column'}>
                  {options?.map((option: DropdownMenuItemType) =>
                    option.type !== 'divider' ? (
                      !option.children ? (
                        <Dropdown.DropdownMenuItem
                          disabled={option.disabled}
                          key={option.key}
                          css={option.css}
                          className={
                            showActiveState && selectedMenuItem && option.key == selectedMenuItem.key ? 'active' : ''
                          }
                          onSelect={e => {
                            handleMenuClick(option, e)
                            if (option.disableCloseOnSelect) e.preventDefault()
                          }}
                          type={option.type}>
                          {option.iconName && (
                            <Dropdown.DropdownIcon className="material-icons-outlined">
                              {option.iconName}
                            </Dropdown.DropdownIcon>
                          )}
                          <Text variant={textVariant}>{option.label}</Text>
                        </Dropdown.DropdownMenuItem>
                      ) : (
                        <DropdownMenuPrimitive.Root key={option.key}>
                          <DropdownMenuTriggerItem>
                            <Dropdown.DropdownIcon className="material-icons-outlined">
                              {option.iconName}
                            </Dropdown.DropdownIcon>
                            <Text variant={textVariant}>{option.label}</Text>
                            <TriangleRightIcon />
                          </DropdownMenuTriggerItem>
                          <Dropdown.DropdownMenuContent
                            css={{
                              width: 'auto',
                              top: '$5',
                              right: '$3',
                              maxHeight,
                              backgroundColor: color,
                              zIndex: 1,
                              display: 'none',
                            }}
                            softBorder="disable"
                            variant={'light'}
                            side="bottom"
                            avoidCollisions={true}
                            collisionPadding={collisionPadding}
                            sideOffset={sideOffset}>
                            {option.children?.map((subOption: DropdownItemType) =>
                              subOption.type === 'divider' ? (
                                <DropdownMenuSeparator key={'separator' + subOption.key} />
                              ) : (
                                <Dropdown.DropdownMenuItem
                                  className={
                                    selectedSubOption && subOption.key == selectedSubOption.key ? 'active' : ''
                                  }
                                  disabled={subOption.disabled}
                                  key={subOption.key}
                                  onSelect={e => {
                                    handleSubClick(subOption)
                                  }}>
                                  {subOption.iconName && (
                                    <Dropdown.DropdownIcon className="material-icons-outlined">
                                      {subOption.iconName}
                                    </Dropdown.DropdownIcon>
                                  )}
                                  <Text variant={textVariant}>{subOption.label}</Text>
                                </Dropdown.DropdownMenuItem>
                              ),
                            )}
                          </Dropdown.DropdownMenuContent>
                        </DropdownMenuPrimitive.Root>
                      )
                    ) : (
                      <DropdownMenuSeparator key={option.key} />
                    ),
                  )}
                </Flex>
                {sideUi}
              </Flex>
            </Dropdown.DropdownMenuContent>
          </WithPortalWrapper>
        </DropdownMenuRoot>
      </Container>
    )
  },
)

DropdownMenu.displayName = 'DropdownMenu'

const WithPortalWrapper = ({ children, inPortal }: { children: React.ReactNode; inPortal: boolean }) => {
  return inPortal ? <DropdownMenuPrimitive.Portal>{children}</DropdownMenuPrimitive.Portal> : children
}

const Container = styled('div', {
  // have to place this here to wrap the DropdownMenuContentContainer
  '[data-side="top"]': {
    borderRadius: '$4 $4 0 0',
  },
  '[data-side="bottom"]': {
    borderRadius: '0 0 $4 $4',
  },
})

export const DropdownMenuRoot = styled(DropdownMenuPrimitive.Root, {})

const DropdownMenuTriggerItem = styled(DropdownMenuPrimitive.Trigger, {
  ...Dropdown.itemStyles,
  '&:before': {
    content: ' ',
    position: 'absolute',
    top: '$0',
    left: '$0',
    right: '$0',
    bottom: '$0',
    border: '2px solid $sec',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.15s linear',
  },
  '&[data-state="open"]:before': {
    opacity: 1,
    visibility: 'visible',
  },
  '&[data-state="open"]': {
    backgroundColor: '$gs6',
  },
})

const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger, {
  background: '$none',
  border: 0,
  cursor: 'pointer',
  '*': {
    cursor: 'pointer !important',
  },
  '&:focus-visible': {
    outline: 'none',
  },
})

const DropdownMenuSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 2,
  backgroundColor: '$gs6',
})
