import { Avatar } from '@pattern-library/src/basicUI/Avatar'
import { DropdownMenu, DropdownMenuProps } from '@pattern-library/src/basicUI/Dropdowns/DropdownMenu'
import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Text } from '@pattern-library/src/basicUI/Text'
import { FC } from 'react'

import { useGetAllAppContext } from '../../../src/hooks/useGetAllAppContext'

type DropdownWithUserProfileProps = {
  dropdownMenuOptions: DropdownMenuProps['options']
  dropdownMenuItemClick: DropdownMenuProps['onSelectMenuItem']
}

export const DropdownWithUserProfile: FC<DropdownWithUserProfileProps> = ({
  dropdownMenuOptions,
  dropdownMenuItemClick,
}) => {
  const { user } = useGetAllAppContext()
  const userProfilePicture = null

  const firstName = user?.['custom:firstName'] || ''
  const lastName = user?.['custom:lastName'] || ''

  return (
    <DropdownMenu
      inPortal={false}
      options={dropdownMenuOptions}
      onSelectMenuItem={dropdownMenuItemClick}
      textVariant="body2"
      sideOffset={8}
      collisionPadding={8}
      css={{ backgroundColor: '$gs1' }}
      contentMenuCss={{
        zIndex: 1,
      }}>
      <Flex align="center">
        {userProfilePicture ? <div /> : <Avatar firstName={firstName} lastName={lastName} />}
        {(firstName || lastName) && (
          <Text
            css={{ display: 'none', '@bp2': { display: 'inline-block', color: '$gs11', ml: '$2' } }}
            variant="body2">
            {firstName ? firstName : ''} {lastName ? lastName : ''}
          </Text>
        )}
      </Flex>
    </DropdownMenu>
  )
}
