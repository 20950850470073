import useIsMounted from 'ismounted'
import { useState } from 'react'

import { SAVED_FILTERS_LOCAL_STORAGE_KEY } from '../utils/other-utils'

interface StateProps {
  loading: boolean
  error: null | Error
}

type signOut = (options?: { global: boolean }) => Promise<void>

export const useSignout = (): StateProps & { signOut: signOut } => {
  const [state, setState] = useState({
    loading: false,
    error: null,
  })
  const isMounted = useIsMounted()

  const signOut: signOut = async options => {
    const { global } = options || { global: true }
    setState({ loading: true, error: null })
    try {
      const signOut = await import('aws-amplify/auth').then(mod => mod.signOut)
      await signOut({ global })
      // Want to keep the saved filters in local storage
      const localFilters = JSON.parse(localStorage.getItem(SAVED_FILTERS_LOCAL_STORAGE_KEY) || '[]')
      // clear localStorage on logout
      localStorage.clear()
      localStorage.setItem(SAVED_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(localFilters))
      if (isMounted.current) setState({ loading: false, error: null })
    } catch (error: any) {
      console.log('error signing out: ', error)
      if (isMounted.current) setState({ loading: false, error })
    }
  }

  return {
    ...state,
    signOut,
  }
}
